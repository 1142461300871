const navigation = [
  {
      name: 'Homepage',
      href: '/',
      icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path d="M12 3L2 12h3v8h6v-5h2v5h6v-8h3L12 3z" />
          </svg>
      ),
  },
]

export default function Footer() {
  return (
      <footer className="bg-white">
          <div className="mx-auto max-w-7xl px-6 py-2 md:flex md:items-center md:justify-between lg:px-8">
              <div className="flex justify-center md:pt-0 md:order-1">
                  <a href="https://www.nationalgeographic.com/oceans-tomorrow/" className="text-gray-600 hover:text-gray-800">
                      <span className="sr-only">Logo to official website</span>
                      <img src="/logo-natgeo.jpeg" alt="Animadex" className="h-56" />
                  </a>
              </div>
              <div className="flex justify-center space-x-6 md:order-2 md:ml-42">
                  {navigation.map((item) => (
                      <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">{item.name}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8 text-black">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                            </svg>

                      </a>
                  ))}
              </div>
              <div className="mt-8 md:order-1 md:mt-0">
                  <p className="text-center text-xs leading-5 text-gray-500">
                      &copy; 2024 LeylineXR PTE, LTD. All rights reserved. <a href="/dmca" className="text-gray-600 hover:text-gray-800">DMCA</a><br></br> version 1.3.0
                  </p>
              </div>
          </div>
      </footer>
  )
}
