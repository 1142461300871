const bio = {
    dolphin: `Here's a little information regarding the Chinese White Dolphin 🐬
  
  - **Scientific Name**: Sousa chinensis
  - **Habitat**: Tropical and warm temperate coastal waters of Southeast Asia, particularly estuaries and mangroves of Pearl River Delta, Hong Kong, and Taiwan
  - **Diet**: Fish, squid (carnivorous)
  - **Threats**: Water pollution, coastal development, overfishing, boat traffic 
  - **Predators**: Sharks`,
  
    turtle: `Here's a little information regarding the Green Turtle 🐢
  
  - **Scientific Name**: Chelonia mydas
  - **Habitat**: Tropical and subtropical waters worldwide, including the Caribbean, Hawaiian Islands, Great Barrier Reef, and Galápagos Islands
  - **Diet**: Seagrasses and algae (herbivorous)
  - **Threats**: Marine plastic pollution, ghost nets, coastal development, tourists, typhoons, boat traffic, oil spills, overfishing/fishing bycatch, ocean acidification
  - **Predators**: Sharks (e.g., tiger sharks), birds, mammals`,
  
    butterflyfish: `Here's a little information regarding the Hong Kong Butterfly Fish 🐠
  
  - **Scientific Name**: Chaetodon wiebeli
  - **Habitat**: Tropical and subtropical waters, in coral reefs, lagoons, and seagrass beds
  - **Diet**: Algae, coral, invertebrates (omnivorous)
  - **Threats**: Aquarium trade, coral bleaching, ocean warming, coastal pollution, marine plastic pollution
  - **Predators**: Larger fish species like groupers and sharks`,
  
    grouper: `Here's a little information regarding the Hong Kong Grouper 🐟
  
  - **Scientific Name**: Epinephelus akaara
  - **Habitat**: All depths and habitats including seagrass, coral, hard bottom, mangrove
  - **Diet**: Fish and crustaceans (carnivorous)
  - **Threats**: Overfishing, ocean warming, land reclamation, coastal pollution
  - **Predators**: Larger fish, sharks, and rays`,
  
    lionfish: `Here's a little information regarding the Red Lionfish 🦁🐟
  
  - **Scientific Name**: Pterois volitans
  - **Habitat**: Tropical waters, in lagoons and outer coral reefs
  - **Diet**: Fish, crustaceans (carnivore)
  - **Threats**: Microplastics, ocean acidification, biodiversity decline, coastal pollution
  - **Predators**: Sharks, cornetfish, grouper, large eels`,


    ribbontail: `Here's a little information regarding the Round-Ribbontail Ray 
  
  - **Scientific Name**: Taeniurops meyeni
  - **Habitat**: Tropical waters, in lagoons and outer coral reef slopes 
  - **Diet**: Bottom-dwelling fish, mollusks, crustaceans (carnivorous)
  - **Threats**: Ghost nets, fishing bycatch, land reclamation, coastal pollution
  - **Predators**: Larger fish like sharks`,

    seahorse: `Here's a little information regarding the Spotted Seahorse 🌊🐴
  
  - **Scientific Name**: Hippocampus kuda
  - **Habitat**: Tropical waters, in seagrass and marine algae areas, river estuaries, and outer coral reefs
  - **Diet**: Zooplankton
  - **Threats**: Tourist activities, traditional Chinese medicine trade, aquarium trade, coastal development, marine plastic pollution
  - **Predators**: Larger fish like grouper, snapper, eels`,
  
    clownfish: `Here's a little information regarding the Yellowtail Clownfish 🐠
  
  - **Scientific Name**: Amphiprion clarkii
  - **Habitat**: Tropical waters, living in association with sea anemones
  - **Diet**: Algae, zooplankton (omnivorous) 
  - **Threats**: Aquarium trade, microplastics, coral bleaching, land reclamation
  - **Predators**: Larger fish like lionfish, snapper, grouper`,
  
    bambooshark: `Here's a little information regarding the White-Spotted Bamboo Shark 🦈
  
  - **Scientific Name**: Chiloscyllium plagiosum
  - **Habitat**: Tropical waters, coral reefs, rocky or coastal areas 
  - **Diet**: Fish, squid, crustaceans (carnivorous)
  - **Threats**: Ghost nets, fishing bycatch, land reclamation, coastal pollution, marine plastic pollution
  - **Predators**: Larger fish like grouper, sharks, rays`
  };

  export default bio;