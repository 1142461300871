// App.js
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import SignUp from './SignUpPage';
import GalleryPage from './GalleryPage';
import DetailPage from './DetailPage';
import ChatWrapper from './ChatWrapper';
import Footer from './Footer';
import DMCAPage from './DMCAPage';
import NavBar from './NavBar';
import AdminGalleryPage from './AdminGalleryPage'; // Import AdminGalleryPage component

function App() {
  return (
    <div className="App">
      <Router>
        <Content />
      </Router>
    </div>
  );
}

function Content() {
  const location = useLocation();
  const isChatPage = location.pathname.includes('chat');
  const isSignUpPage = location.pathname === '/';
  const isGalleryPage = location.pathname === '/gallery';
  const isAdminGalleryPage = location.pathname === '/admin-gallery';

  return (
    <div className="relative min-h-screen w-full">
      {isSignUpPage && (
        <div
          className="full-height-background"
          style={{
            backgroundImage: "url('/images/mural3.png'), linear-gradient(to bottom, rgba(255, 255, 255, 0) 5%, white 100%)",
            backgroundBlendMode: "overlay",
            opacity: 0.5,
          }}
        ></div>
      )}
      <div className="relative z-10">
        {(isSignUpPage || isGalleryPage || isAdminGalleryPage) && <NavBar isGalleryPage={isGalleryPage || isAdminGalleryPage} />} {/* Conditionally render NavBar */}
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/admin-gallery" element={<AdminGalleryPage />} /> {/* Add the admin gallery route */}
          <Route path="/chat/:chatType" element={<ChatWrapper />} /> 
          <Route path="/gallery/:detailId" element={<DetailPage />} />
          <Route path="/dmca" element={<DMCAPage />} />
        </Routes>
        {!isChatPage && <Footer />}
      </div>
    </div>
  );
}

export default App;
