import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Image from './ImageComponent';
import ReactMarkdown from 'react-markdown';

const imgClass = 'bg-teal-100 rounded-lg w-full max-[360px]:min-h-60 min-h-72 lg:w-[29rem] lg:h-[29rem] 2xl:w-[40rem] 2xl:h-[40rem] object-cover overflow-hidden';

export default function DetailPage() {
  const { detailId } = useParams();
  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchItemDetails(detailId);
  }, [detailId]);

  const fetchItemDetails = async (objectId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`/.netlify/functions/getAnimadexById?id=${objectId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      console.log('Fetched item details:', result);
      setItemDetails(result);
    } catch (error) {
      console.error('Error fetching item details:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  if (!itemDetails) {
    return <div>No details available</div>;
  }

  const { user, bio, media, createdAt } = itemDetails;
  const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://imagineoceans.app/gallery/${detailId}`;

  // Convert createdAt to a readable format
  const creationDate = new Date(createdAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div className="mx-8 py-8 md:mx-20 min-h-[calc(100vh-228px)] sm:min-h-[calc(100vh-240px)]">
      <Link to="/gallery" className="inline-block font-bold mb-8 text-black bg-white border border-black rounded py-2 px-4 transition-colors lg:hidden">
        Back to Gallery
      </Link>
      <div className='flex justify-center items-center flex-col lg:flex-row lg:items-start'>
        <Image src={media || ''} alt="animal evolution" className={imgClass} />
        <div className="py-4 text-left lg:text-justify lg:pl-8 lg:pt-0 lg:w-1/2">
          <Link to="/gallery" className="hidden font-bold text-black bg-white border border-black rounded py-2 px-4 transition-colors lg:inline-block lg:hover:bg-[#FCD804]">
            Back to Gallery
          </Link>
          <h2 className="font-bold text-lg text-center capitalize pt-4 lg:pt-8 lg:text-left">{user}</h2>
          <span className="block text-center lg:text-left"><strong>ID: </strong>{detailId}</span> 
          <span className="block text-center lg:text-left"><strong>Archived: </strong>{creationDate}</span> <br></br>
          <ReactMarkdown className="text-gray-700 text-sm">
            {bio}
          </ReactMarkdown>
          <div className="flex mt-8 justify-center sm:justify-start">
            <img src={qrCodeUrl} alt="QR code" className="w-20 h-20 opacity-50" />
          </div>
        </div>
      </div>
    </div>
  );
}
