import React from 'react';

function Input({ prompt, handleInputChange, handleSubmit, loading, stage, showNext }) {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(prompt);
    }
  };

  return (
    <div className="sticky bottom-0 bg-white py-4">
      <input
        type="text"
        value={prompt}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder={stage === 'name' ? "Enter your name" : "Enter your email"}
        className="w-full p-2 border border-gray-300 rounded mb-2"
      />
      <button
        onClick={() => handleSubmit(prompt)}
        disabled={loading}
        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300"
      >
        {showNext ? "Next" : "Submit"}
      </button>
    </div>
  );
}

export default Input;
