import React, { useRef, useEffect } from 'react';
import Message from './Message';

function ChatWindow({ messages, loading, imageGenerationInProgress, seaEmojis, currentEmojiIndex }) {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="chat-window flex flex-grow flex-col overflow-auto bg-gray-100 p-4 rounded-lg mb-4 max-h-full">
      {messages.map((message, index) => (
        <Message key={index} message={message} />
      ))}
      {loading && (
        <div className="text-center text-gray-500">Loading...</div>
      )}
      {imageGenerationInProgress && (
        <div className="text-center text-gray-500 text-4xl">{seaEmojis[currentEmojiIndex]}</div>
      )}
      <div ref={messagesEndRef} className="h-2 w-full" />
    </div>
  );
}

export default ChatWindow;
