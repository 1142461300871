import Filter from 'bad-words';
import generateCreatureBioAndImage from './generateCreatureBioAndImage';
import { profaneWords } from './Profanity';

const filter = new Filter();

const storeUserDetails = async (userName, userEmail) => {
  try {
    const res = await fetch('/.netlify/functions/generateJob', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userName, userEmail }),
    });

    if (!res.ok) {
      const errorText = await res.text();
      throw new Error(`Failed to store user details: ${errorText}`);
    }
  } catch (error) {
    console.error('Error storing user details: ', error);
  }
};

const validateEmail = (email) => {
  const trimmedEmail = email.trim();
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(trimmedEmail);
};

const stripUserDetails = (messages, userName, userEmail) => {
  const userDetails = [userName, userEmail];
  return messages.map((msg) => {
    if (msg.sender === 'user') {
      let content = msg.text;
      userDetails.forEach(detail => {
        const regex = new RegExp(detail, 'gi');
        content = content.replace(regex, '[REDACTED]');
      });
      return { ...msg, text: content };
    }
    return msg;
  });
};

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const collapseConsecutiveCharacters = (string) => {
  return string.replace(/(.)\1+/g, '$1');
};

const containsProfanity = (text) => {
  const normalizedText = collapseConsecutiveCharacters(text);

  if (filter.isProfane(normalizedText)) {
    return true;
  }

  for (const word of profaneWords) {
    const escapedWord = escapeRegExp(word);
    const regex = new RegExp(`${escapedWord.split(' ').join('\\s*')}`, 'i');
    if (regex.test(normalizedText)) {
      return true;
    }
  }

  return false;
};

// Function to check for special characters including : ; , .
const containsSpecialCharacters = (text) => {
  const specialCharPattern = /[!@#$%^&*(),.?":{}|<>';:]/g;
  return specialCharPattern.test(text);
};

const checkProfanity = async (text) => {
  try {
    const res = await fetch('/.netlify/functions/checkProfanity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt: text }),
    });

    if (!res.ok) {
      const errorText = await res.text();
      throw new Error(`Profanity check failed: ${errorText}`);
    }

    const result = await res.json();
    return result.response.trim();
  } catch (error) {
    console.error('Error checking profanity: ', error);
    return 'Error';
  }
};

const handleSubmit = async (
  inputPrompt,
  stage,
  userName,
  userEmail,
  setUserName,
  setUserEmail,
  setMessages,
  setPrompt,
  setShowOptions,
  setLoading,
  setStage,
  setStartTime,
  messages,
  chatType,
  startTime,
  setImageGenerationInProgress,
  setGameEnded,
  setShowNext 
) => {
  inputPrompt = inputPrompt || ''; // Ensure inputPrompt is a string
  if ((typeof inputPrompt !== 'string' || inputPrompt.trim() === '') && stage !== 'ready') return;

  const newMessage = { sender: 'user', text: inputPrompt };
  setMessages((prevMessages) => [...prevMessages, newMessage]);
  setPrompt('');
  setShowOptions(false);

  if (stage === 'email') {
    if (validateEmail(inputPrompt)) {
      setUserEmail(inputPrompt);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'ai', text: 'Thank you! Now, please **name your creature:**' }
      ]);
      setStage('name');

      // Store user details in the database
      await storeUserDetails(userName, inputPrompt);
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'ai', text: 'Sorry 😔 this is an invalid email format.\n\nPlease enter a valid email:' }
      ]);
    }
  } else if (stage === 'name') {
    if (containsProfanity(inputPrompt)) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'ai', text: 'Sorry, that name contains inappropriate language. 🚫 Please enter a different name:' }
      ]);
    } else if (containsSpecialCharacters(inputPrompt)) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'ai', text: 'Sorry, that name contains special characters. Please enter a different name without special characters:' }
      ]);
    } else if (inputPrompt.length > 10) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'ai', text: 'Sorry, the name is too long. Please enter a name with no more than 10 characters:' }
      ]);
    } else {
      // Perform final profanity check with Netlify function
      const finalProfanityCheckResult = await checkProfanity(inputPrompt);

      if (finalProfanityCheckResult === 'Yes') {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'ai', text: 'Sorry, that name contains inappropriate language. 🚫 Please enter a different name:' }
        ]);
      } else if (finalProfanityCheckResult === 'No') {
        setUserName(inputPrompt);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'ai', text: `Creature **${inputPrompt}** registered! 🌟` },
          { sender: 'ai', text: 'Click "Ready!" to start the game.' }
        ]);
        setStage('ready');
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'ai', text: 'There was an error checking the name for profanity. Please try again.' }
        ]);
      }
    }
  } else if (stage === 'ready' && inputPrompt === 'Ready!') {
    setLoading(true);
    setStartTime(Date.now()); // Set the start time when the game starts

    // Filter and sanitize messages to remove user details
    const sanitizedMessages = stripUserDetails(messages, userName, userEmail);

    // Format messages for OpenAI
    const formattedMessages = sanitizedMessages.slice(1).map((msg) => ({
      role: msg.sender === 'ai' ? 'assistant' : 'user',
      content: msg.text
    }));
    formattedMessages.push({ role: 'user', content: 'Ready!' });

    try {
      const res = await fetch('/.netlify/functions/callOpenai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages: formattedMessages, chatType }), // Exclude userName and userEmail
      });

      if (res.ok) {
        const result = await res.json();
        let aiMessageText = result.response;

        const fullHistory = [...formattedMessages, { role: 'assistant', content: aiMessageText }]
          .map((msg) => `${msg.role}: ${msg.content}`)
          .join('\n');

        if (aiMessageText.endsWith('&END&') || aiMessageText.endsWith('**&END&**')) {
          aiMessageText = aiMessageText.replace('&END&', '').replace('**&END&**', '');
          await generateCreatureBioAndImage(fullHistory, aiMessageText, setMessages, startTime, setImageGenerationInProgress, setGameEnded, userName, userEmail, chatType);
        } else {
          // Split the message based on "Scenario"
          if (aiMessageText.includes('**Scenario')) {
            const splitIndex = aiMessageText.indexOf('**Scenario');
            const beforeScenario = aiMessageText.substring(0, splitIndex).trim();
            const afterScenario = aiMessageText.substring(splitIndex).trim();

            if (beforeScenario) {
              const aiMessageBefore = { sender: 'ai', text: beforeScenario };
              setMessages((prevMessages) => [...prevMessages, aiMessageBefore]);
            }

            if (afterScenario) {
              const aiMessageAfter = { sender: 'ai', text: afterScenario };
              setMessages((prevMessages) => [...prevMessages, aiMessageAfter]);
            }
          } else {
            const aiMessage = { sender: 'ai', text: aiMessageText };
            setMessages((prevMessages) => [...prevMessages, aiMessage]);
          }

          // Check for options A, B, C, D in the AI message
          if (['A.', 'B.', 'C.', 'D.'].some(option => aiMessageText.includes(option))) {
            setShowOptions(true); // Show options if A, B, C, D are present
            setShowNext(false); // Ensure showNext is false
          } else {
            setShowNext(true); // Show "Next" button if A, B, C, D are not present
          }
        }
      } else {
        const errorText = await res.text();
        throw new Error(`Failed to fetch API: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const errorMessage = { sender: 'ai', text: 'Error: ' + error.message };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
    setLoading(false);
  } else {
    setLoading(true);

    const sanitizedMessages = stripUserDetails(messages, userName, userEmail);

    const formattedMessages = sanitizedMessages.slice(1).map((msg) => ({
      role: msg.sender === 'ai' ? 'assistant' : 'user',
      content: msg.text
    }));
    formattedMessages.push({ role: 'user', content: inputPrompt });

    try {
      const res = await fetch('/.netlify/functions/callOpenai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages: formattedMessages, chatType }), // Exclude userName and userEmail
      });

      if (res.ok) {
        const result = await res.json();
        let aiMessageText = result.response;

        const fullHistory = [...formattedMessages, { role: 'assistant', content: aiMessageText }]
          .map((msg) => `${msg.role}: ${msg.content}`)
          .join('\n');

        if (aiMessageText.endsWith('&END&') || aiMessageText.endsWith('**&END&**')) {
          aiMessageText = aiMessageText.replace('&END&', '').replace('**&END&**', '');
          await generateCreatureBioAndImage(fullHistory, aiMessageText, setMessages, startTime, setImageGenerationInProgress, setGameEnded, userName, userEmail, chatType);
        } else {
          // Split the message based on "Scenario"
          if (aiMessageText.includes('**Scenario')) {
            const splitIndex = aiMessageText.indexOf('**Scenario');
            const beforeScenario = aiMessageText.substring(0, splitIndex).trim();
            const afterScenario = aiMessageText.substring(splitIndex).trim();

            if (beforeScenario) {
              const aiMessageBefore = { sender: 'ai', text: beforeScenario };
              setMessages((prevMessages) => [...prevMessages, aiMessageBefore]);
            }

            if (afterScenario) {
              const aiMessageAfter = { sender: 'ai', text: afterScenario };
              setMessages((prevMessages) => [...prevMessages, aiMessageAfter]);
            }
          } else {
            const aiMessage = { sender: 'ai', text: aiMessageText };
            setMessages((prevMessages) => [...prevMessages, aiMessage]);
          }

          // Check for options A, B, C, D in the AI message
          if (['A.', 'B.', 'C.', 'D.'].some(option => aiMessageText.includes(option))) {
            setShowOptions(true); // Show options if A, B, C, D are present
            setShowNext(false); // Ensure showNext is false
          } else {
            setShowNext(true); // Show "Next" button if A, B, C, D are not present
          }
        }
      } else {
        const errorText = await res.text();
        throw new Error(`Failed to fetch API: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const errorMessage = { sender: 'ai', text: 'Error: ' + error.message };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
    setLoading(false);
  }
};

export default handleSubmit;
