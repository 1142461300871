import React from 'react';
import ReactMarkdown from 'react-markdown';
import Filter from 'bad-words';
import { profaneWords } from './utils/Profanity'; // Assuming Profanity.js exports an array of words

const filter = new Filter();

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const collapseConsecutiveCharacters = (string) => {
  return string.replace(/(.)\1+/g, '$1');
};

const containsProfanity = (text) => {
  const normalizedText = collapseConsecutiveCharacters(text);

  if (filter.isProfane(normalizedText)) {
    return true;
  }

  for (const word of profaneWords) {
    const escapedWord = escapeRegExp(word);
    const regex = new RegExp(`${escapedWord.split(' ').join('\\s*')}`, 'i');
    if (regex.test(normalizedText)) {
      return true;
    }
  }

  return false;
};

function Message({ message }) {
  const isProfane = message.sender === 'user' && containsProfanity(message.text);
  const content = isProfane ? 'Inappropriate content detected 👁️‍🗨️' : message.text;

  return (
    <div className={`message ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
      <div
        className={`inline-block p-2 rounded-lg mb-2 max-w-full overflow-auto ${
          message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'
        }`}
      >
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  );
}

export default Message;
