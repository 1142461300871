import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MagnifyingGlassIcon, AdjustmentsHorizontalIcon, AdjustmentsVerticalIcon, XCircleIcon, ListBulletIcon, Squares2X2Icon} from '@heroicons/react/20/solid';
import Image from './ImageComponent';
import DropDown from './DropDown';
import Pagination from './Pagination';

export default function AdminGalleryPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAZ, setIsAZ] = useState(true);
  const [isNewestFirst, setIsNewestFirst] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [dateOrder, setDateOrder] = useState('Newest-Oldest');
  const [alphaOrder, setAlphaOrder] = useState('A-Z');
  const [toggleGallery, setToggleGallery] = useState(true);
  const toggleCards = () => {
    setToggleGallery(!toggleGallery);
  };
  const gridClasses = `grid ${toggleGallery ? 'grid-cols-1' : 'grid-cols-2'} sm:grid-cols-2 lg:grid-cols-3 lg:grid-rows-3 gap-4 pt-8`;
  const itemsPerPage = toggleGallery ? 9 : 8;
  const imageClasses = `${toggleGallery ? 'h-52' : 'h-48'} w-48`;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const filteredData = data.filter(item => {
    const search = item.user.toLowerCase().includes(searchTerm.toLowerCase());
    return search;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('/.netlify/functions/getAnimadex');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sortAlphabetically = () => {
    const sorted = [...data].sort((a, b) => 
      isAZ ? a.user.localeCompare(b.user) : b.user.localeCompare(a.user)
    );

    setAlphaOrder(alphaOrder === 'A-Z' ? 'Z-A' : 'A-Z');
    setIsAZ(!isAZ);
    setData(sorted);
  };

  const sortByDate = () => {
    const sorted = [...data].sort((a, b) => 
      isNewestFirst ? new Date(b.createdAt) - new Date(a.createdAt) : new Date(a.createdAt) - new Date(b.createdAt)
    );
    setDateOrder(dateOrder === 'Newest-Oldest' ? 'Oldest-Newest' : 'Newest-Oldest');
    setIsNewestFirst(!isNewestFirst);
    setData(sorted);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [toggleIcon, setToggleIcon] = useState(true);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    setToggleIcon(!toggleIcon);
  };

  const handleDelete = async (id) => {
    try {
      console.log(`Attempting to delete entry with ID: ${id}`);
      const response = await fetch(`/.netlify/functions/deleteEntry?id=${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        console.log(`Successfully deleted entry with ID: ${id}`);
        setData(data.filter(item => item.id !== id));
      } else {
        console.error(`Failed to delete entry. Status: ${response.status}`);
        throw new Error('Failed to delete entry');
      }
    } catch (error) {
      console.error(`Error: ${error.message}`);
      setError(error.message);
    }
  };
  

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === process.env.REACT_APP_ADMIN_PASSWORD) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <form onSubmit={handlePasswordSubmit} className="p-4 bg-white shadow-md rounded">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Enter Admin Password
          </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-12 px-4"
            style={{ fontSize: '1.25rem' }}
          />
          <button type="submit" className="mt-2 w-full bg-blue-500 text-white py-2 rounded-md">
            Submit
          </button>
        </form>
      </div>
    );
  }
  

  return (
    <div className="max-w-7xl mt-6 pb-6 mx-6 min-[480px]:px-[12vw] min-h-[calc(100vh-160px)] sm:px-0 lg:relative xl:mx-auto">
      <h1 className="text-lg font-bold pb-4 text-left">
        Admin Creatures Gallery
      </h1>
      <p className='text-left pb-4'>
        This is an admin gallery of Animadex entries. Click on an entry to view more details. Use the 'X' button to delete an entry.
      </p>
      <div className='flex relative lg:absolute lg:top-0 lg:right-4'>
        <div className="flex flex-1 items-center justify-center pr-2 lg:justify-end">
          <div className="w-full lg:max-w-xs">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Search"
                type="search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <button onClick={toggleDropdown} className='content-center p-2 bg-black rounded'>
          {toggleIcon &&
            <AdjustmentsHorizontalIcon className="h-5 w-5 text-gray-500 fill-white" aria-hidden="true" />
          }
          {showDropdown &&
            <AdjustmentsVerticalIcon className="h-5 w-5 text-gray-500 fill-white" aria-hidden="true" />
          }
        </button>
        <button onClick={toggleCards} className='content-center p-2 ml-1 border border-black rounded'>
          {toggleGallery ? (
            <Squares2X2Icon className="h-5 w-5 fill-black" aria-hidden="true" />
            ) : (
            <ListBulletIcon className="h-5 w-5 fill-black" aria-hidden="true" />
          )}
        </button>
        {showDropdown && 
          <DropDown className='bg-slate-100 absolute top-9 right-0 left-0'
          dateOrder={dateOrder}
          alphaOrder={alphaOrder} 
          sortAlphabetically={sortAlphabetically}
          sortByDate={sortByDate}
          />
        }
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">Error: {error}</p>}
      {data && (
        <>
          <div className={gridClasses}>
            {currentItems.map((item) => (
              <div key={item.id} className="relative bg-white pt-4 rounded-lg shadow-md cursor-pointer">
                <Link to={`/gallery/${item.id}`} className="no-underline text-black lg:hover:-translate-y-2 transition-transform ease-in-out">
                  <div className="flex justify-center">
                    {item.media && (
                      <Image
                        src={item.media}
                        alt={item.user}
                        className={imageClasses} 
                      />
                    )}
                  </div>
                  <span className='block p-4'>
                    <strong>User:</strong> {item.user}
                  </span>
                </Link>
                <button onClick={() => handleDelete(item.id)} className="absolute top-2 right-2 text-red-500">
                  <XCircleIcon className="h-6 w-6" />
                </button>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
}
