import React from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const numberClass = 'w-8 py-2 rounded-lg';

    const renderPaginationMobile = () => {
        const pages = [];

        if (totalPages <= 3) {
            // Show all pages if total pages are less than or equal to 3
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        onClick={() => onPageChange(i)}
                        className={`${numberClass} ${currentPage === i ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            pages.push(
                <button
                    key={1}
                    onClick={() => onPageChange(1)}
                    className={`${numberClass} ${currentPage === 1 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
                >
                    1
                </button>
            );

            if (currentPage > 2) {
                pages.push(<span key="start-dots" className="px-2 py-2">...</span>);
            }

            if (currentPage > 1 && currentPage < totalPages) {
                pages.push(
                    <button
                        key={currentPage}
                        onClick={() => onPageChange(currentPage)}
                        className={`${numberClass} ${currentPage ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
                    >
                        {currentPage}
                    </button>
                );
            }

            if (currentPage < totalPages - 1) {
                pages.push(<span key="end-dots" className="px-2 py-2">...</span>);
            }

            pages.push(
                <button
                    key={totalPages}
                    onClick={() => onPageChange(totalPages)}
                    className={`${numberClass} ${currentPage === totalPages ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };

    const renderPaginationDesktop = () => {
        const pages = [];
        
        if (currentPage !== 1 && currentPage !== 2) {
            pages.push(
                <button
                    key={1}
                    onClick={() => onPageChange(1)}
                    className={`${numberClass} ${currentPage === 1 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
                >
                    1
                </button>
            );
        }

        if (currentPage > 3) {
            pages.push(<span key="start-dots" className="px-2 py-2">...</span>);
        }

        const startPage = Math.max(1, currentPage - 1);
        const endPage = Math.min(totalPages - 1, currentPage + 1);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => onPageChange(i)}
                    className={`${numberClass} ${currentPage === i ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
                >
                    {i}
                </button>
            );
        }

        if (currentPage < totalPages - 2) {
            pages.push(<span key="end-dots" className="px-2 py-2">...</span>);
        }

        pages.push(
            <button
                key={totalPages}
                onClick={() => onPageChange(totalPages)}
                className={`${numberClass} ${currentPage === totalPages ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
                {totalPages}
            </button>
        );

        return pages;
    };

    return (
        <div className="flex justify-center mt-4 gap-1 relative">
            {currentPage > 1 && (
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    className="px-2 py-2 border bg-white text-cyan-500 rounded-lg absolute left-0"
                >
                    <ArrowLeftIcon className='h-5 w-5'/>
                </button>
            )}
            <div className="flex sm:hidden gap-1">
                {renderPaginationMobile()}
            </div>
            <div className="hidden sm:flex gap-1">
                {renderPaginationDesktop()}
            </div>
            {currentPage < totalPages && (
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    className="px-2 py-2 border bg-white text-cyan-500 rounded-lg absolute right-0"
                >
                    <ArrowRightIcon className='h-5 w-5'/>
                </button>
            )}
        </div>
    );
};

export default Pagination;
