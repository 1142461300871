import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const chatRoomLinks = [
  { name: 'Chinese White Dolphin', url: '/chat/dolphin', photoUrl: '/images/ChineseWhiteDolphin.png' },
  { name: 'Hongkong Butterflyfish', url: '/chat/butterflyfish', photoUrl: '/images/HongkongButterflyfish.png' },
  { name: 'Hongkong Grouper', url: '/chat/grouper', photoUrl: '/images/HongkongGrouper.png' },
  { name: 'Red Lionfish', url: '/chat/lionfish', photoUrl: '/images/RedLionfish.png' },
  { name: 'Round-ribbontail Ray', url: '/chat/ribbontail', photoUrl: '/images/RoundribbontailRay.png' },
  { name: 'Spotted Seahorse', url: '/chat/seahorse', photoUrl: '/images/SpottedSeahorse.png' },
  { name: 'Green Turtle', url: '/chat/turtle', photoUrl: '/images/Turtle.png' },
  { name: 'Whitespotted Bamboo Shark', url: '/chat/bambooshark', photoUrl: '/images/WhitespottedBambooShark.png' },
  { name: 'Yellowtail Clownfish', url: '/chat/clownfish', photoUrl: '/images/YellowtailClownfish.png' },
];

const SignUpPage = () => {
  const history = useNavigate();
  const [isAboutVisible, setIsAboutVisible] = useState(false);

  const handleCardClick = (url, photoUrl) => {
    history(url, { state: { photoUrl } });
  };

  const toggleAboutSection = () => {
    setIsAboutVisible(!isAboutVisible);
  };

  return (
    <div className="max-w-7xl mx-8 lg:px-4 xl:mx-auto py-8 min-h-[76vh] sm:min-h-[85vh] lg:relative">
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h1 className="text-lg font-bold pb-4 text-left">Creature Selector</h1>
        <p className="text-left pb-4">
          Click on a creature to begin your <strong>100 year journey</strong>.
        </p>
        {isAboutVisible && (
          <p className="mt-4 text-left">
            Welcome to the <strong>IMAGINE WE ALL MAKE IT</strong> marine conservation game.
            <br></br><br></br>Embark on a journey through Hong Kong's waters and help marine species thrive in future scenarios impacted by climate change. Choose a hero creature, navigate critical decisions, and learn about marine conservation. Your choices shape the future, and with each step, you will gain insights and create AI-generated images of thriving marine life. Dive in and become a marine conservation hero today!
          </p>
        )}
        <div className="flex justify-end">
          <button
            className="flex items-center text-gray-500"
            onClick={toggleAboutSection}
          >
            {isAboutVisible ? 'Read Less' : 'Read More'}
            {isAboutVisible ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
              </svg>

            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            )}
          </button>
        </div>
      </div>
      <div className="relative pt-8">
        <div className="grid grid-cols-1 min-[320px]:grid-cols-3 sm:grid-cols-3 gap-2.5 md:gap-4">
          {chatRoomLinks.map((link, index) => (
            <div className="bg-white p-3 rounded-lg shadow-md cursor-pointer" onClick={() => handleCardClick(link.url, link.photoUrl)} key={index}>
              <div className="flex flex-col items-center">
                <img src={link.photoUrl} alt={link.name} className="w-20 h-20 transform transition-transform duration-300 hover:scale-130" />
                <span className="text-center mt-2 hidden sm:block">{link.name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
