export default function DropDown({ className, sortAlphabetically, sortByDate, dateOrder, alphaOrder }) {

    const handleDateClick = () => {
        sortByDate();
    };

    const handleAlphaClick = () => {
        sortAlphabetically();
    };

    return (
        <ul className={`list-none p-0 mt-[2px] m-0 text-left rounded-b-lg text-gray-900 font-bold z-10 ${className}`}>
            <li className="block">
                <button className='text-left capitalize p-4 w-full' onClick={handleDateClick}>
                    {dateOrder}
                </button>
            </li>
            <li className="block">
                <button className='text-left capitalize p-4 w-full' onClick={handleAlphaClick}>
                    {alphaOrder}
                </button>
            </li>
        </ul>
    );
}
