import React from 'react';

function Options({ handleOptionClick }) {
  return (
    <div className="options flex justify-between">
      <button onClick={() => handleOptionClick('A')} className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2 mr-2">A</button>
      <button onClick={() => handleOptionClick('B')} className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2 mr-2">B</button>
      <button onClick={() => handleOptionClick('C')} className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2 mr-2">C</button>
      <button onClick={() => handleOptionClick('D')} className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2">D</button>
    </div>
  );
}

export default Options;
