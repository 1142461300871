import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ChatWindow from './ChatWindow';
import Input from './Input';
import Options from './Options';
import handleSubmit from './utils/handleSubmit';
import bio from './bioData'; // Adjust the path if necessary

const seaEmojis = ['🐬', '🐢', '🐠', '🐟', '🦈', '🐳', '🌊', '🐋', '🦀', '🦐', '🪼', '🐚', '🫧', '🪸', '🤿', '🏝️', '🌊', '🐙', '🐠', '🦈'];

function ChatPage({ chatType }) {
  const location = useLocation();
  const { photoUrl } = location.state || {};
  const welcomeMessage = `Welcome to the\n\n✨**IMAGINE WE ALL MAKE IT**✨ game!`;
  const bioMessage = bio[chatType] ? bio[chatType] : '';
  const enterEmailMessage = `We will embark on a journey spanning a hundred years, where you will encounter various scenarios and you must choose how you would act to protect the ${chatType}.\n\nFirst things first, please **enter your school email:**`;

  const initialMessages = [
    { sender: 'ai', text: welcomeMessage },
    { sender: 'ai', text: bioMessage },
    { sender: 'ai', text: enterEmailMessage }
  ];

  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState(initialMessages);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState('email');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [showNext, setShowNext] = useState(false); // New state to control "Next" button
  const [gameEnded, setGameEnded] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [imageGenerationInProgress, setImageGenerationInProgress] = useState(false);
  const [currentEmojiIndex, setCurrentEmojiIndex] = useState(0);

  useEffect(() => {
    setMessages([
      { sender: 'ai', text: welcomeMessage },
      { sender: 'ai', text: bioMessage },
      { sender: 'ai', text: enterEmailMessage }
    ]);
  }, [welcomeMessage, bioMessage, enterEmailMessage]);

  useEffect(() => {
    let interval;
    if (imageGenerationInProgress) {
      interval = setInterval(() => {
        setCurrentEmojiIndex((prevIndex) => (prevIndex + 1) % seaEmojis.length);
      }, 100);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [imageGenerationInProgress]);

  const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleOptionClick = (option) => {
    console.log('Option clicked:', option);
    handleSubmit(
      option,
      stage,
      userName,
      userEmail,
      setUserName,
      setUserEmail,
      setMessages,
      setPrompt,
      setShowOptions,
      setLoading,
      setStage,
      setStartTime,
      messages,
      chatType,
      startTime,
      setImageGenerationInProgress,
      setGameEnded,
      setShowNext // Pass the state setter to handleSubmit
    );
  };

  const handlePlayAgain = () => {
    setMessages([{ sender: 'ai', text: 'New game started! Please **name your new creature:**' }]);
    setStage('name');
    setGameEnded(false);
    setShowOptions(false);
    setShowNext(false); // Reset showNext
    setStartTime(null);
    setUserName(''); // Reset userName
  };

  return (
    <div className="min-h-screen flex flex-col sticky top-0 bottom-0 left-0 bg-white w-full">
      <div className="w-full mx-auto px-4 border border-gray-200 shadow-lg rounded-lg flex flex-col flex-grow">
        <div className="flex justify-between items-center py-4 sticky top-0 bg-white">
          <div className="flex items-center">
            <Link to="/" className="text-black lg:hover:bg-[#FCD804] font-bold py-2 px-2 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5l-7.5-7.5 7.5-7.5" />
            </svg>

            </Link>
            <img src={photoUrl} alt={chatType} className="w-12 h-12 ml-2" />
            <h1 className="text-base font-semibold capitalize ml-1">{chatType}</h1>
          </div>
          <Link to="/gallery" className="text-black rounded lg:hover:bg-[#FCD804] font-bold py-2 px-4 border border-black">
            Gallery 
          </Link>
        </div>
        <ChatWindow messages={messages} loading={loading} imageGenerationInProgress={imageGenerationInProgress} seaEmojis={seaEmojis} currentEmojiIndex={currentEmojiIndex} />
        {showOptions && <Options handleOptionClick={handleOptionClick} />}
        {!showOptions && !gameEnded && stage !== 'ready' && (
          <Input prompt={prompt} handleInputChange={handleInputChange} handleSubmit={() => handleSubmit(
            prompt,
            stage,
            userName,
            userEmail,
            setUserName,
            setUserEmail,
            setMessages,
            setPrompt,
            setShowOptions,
            setLoading,
            setStage,
            setStartTime,
            messages,
            chatType,
            startTime,
            setImageGenerationInProgress,
            setGameEnded,
            setShowNext // Pass the state setter to handleSubmit
          )} loading={loading} stage={stage} showNext={showNext} /> // Pass showNext to Input
        )}
        {!showOptions && !gameEnded && stage === 'ready' && (
          <div className="sticky bottom-0 bg-white py-4">
            <button
              onClick={() => handleSubmit(
                'Ready!',
                stage,
                userName,
                userEmail,
                setUserName,
                setUserEmail,
                setMessages,
                setPrompt,
                setShowOptions,
                setLoading,
                setStage,
                setStartTime,
                messages,
                chatType,
                startTime,
                setImageGenerationInProgress,
                setGameEnded,
                setShowNext // Pass the state setter to handleSubmit
              )}
              disabled={loading || imageGenerationInProgress}
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300"
            >
              Ready!
            </button>
          </div>
        )}
        {gameEnded && (
          <div className="sticky bottom-0 bg-white py-4">
            <button
              onClick={handlePlayAgain}
              className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Play Again
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatPage;