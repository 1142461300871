/* Usage:
import Image from './ImageComponent';
<Image src={media && media.length > 0 ? `/.netlify/functions/proxyImage?imageId=${media[0]}`} alt="animal evolution" className={imgClass}/>
*/
import React from 'react';

export default function Image({ src, alt, key, className }) {
    return (
        <figure className={className}>
            {src && (
                <picture className='w-full overflow-hidden'>
                    <img 
                        src={src}
                        className='w-full h-full'
                        alt={alt}
                        key={key}
                        loading="lazy"
                        width='1024'
                        height='1024'
                    />
                </picture>
            )}
        </figure>
    )
};