import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import ChatPage from './ChatPage';

const validChatTypes = ['dolphin', 'turtle', 'butterflyfish', 'ribbontail', 'grouper', 'lionfish', 'seahorse', 'bambooshark', 'clownfish'];

function ChatWrapper() {
  const { chatType } = useParams();

  if (!validChatTypes.includes(chatType)) {
    return <Navigate to="/" />; // Redirect to home page if chatType is invalid
  }

  return <ChatPage chatType={chatType} />;
}

export default ChatWrapper;

